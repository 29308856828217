<template>
  <div class="date-card">
    <div class="row">
      <div class="col-3">
        <Date v-if='obj && monthAbbr' :obj="obj" :i18n='monthAbbr' />
      </div>
      <div class="col-9">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Date from '@/components/date-card/Date.vue'

export default {
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'date-card': {},
        }
      },
    },
    obj: Object
  },
  data() {
    return {
      'monthAbbr': {},
    }
  },
  created() {
    this.monthAbbr = this.i18n['months-abbreviations']
  },
  components: {
    Date: Date,
  },
  methods: {
    charLimit(text, limit) {
      if (text !== null) {
        return text.length > limit ? text.substring(0, limit) + '...' : text
      }
    },
  },
  watch: {
    i18n: function(newVal, oldVal) {
      if (newVal && newVal['months-abbreviations']) {
        this.monthAbbr = newVal['months-abbreviations']
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
.date-card {
  border-radius: 6px;
  margin-bottom: 12px;
  background-color: #fff;
  .content {
    padding: 11px 20px 11px 20px;
    cursor: pointer;
  }
  .row {
    flex-wrap: nowrap;
    margin-left: 0;
    margin-right: 0;
    height: 100%;
  }
  .col-3 {
    max-width: none;
    width: auto;
    flex: 0 0 auto;
    padding: 0;
  }
  .col-9 {
    max-width: none;
    width: auto;
    flex: 1 1 auto;
    padding: 0;
  }
}
</style>
