<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ translations.tcGideonCardProgram }}</h1>
    </header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage" :i18n="translations.components" />
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load()" :tooltip="translations.tcTooltip"  :i18n="translations.components" />
      <!-- / Camp Selector -->

      <div class="page-body container mx-med">
        <section class="section-1">
          <h4>{{ translations.tcRecentRestockingVisits }}</h4>
          <div class="row">
            <div class="col col-6">
              <DateCard v-for="(visit, index) in restockingVisitSummary" :key="index" :obj="visit.date" :i18n='translations.common'>
                <BasicDateCard v-if="translations.common" :obj="visit" :i18n="translations.components" />
              </DateCard>
              <div class="d-flex button-bar mb-5">
                <b-button variant="primary" class="mr-2 mb-3" @click="handleVisitListClick">
                  {{ translations.tcViewMore }}
                </b-button>
                <b-button
                  v-if="iCanSee(secured_gideoncard_visit_add_controls.add_gideoncard_restocking_visit_button)"
                  variant="primary"
                  class="mr-2 mb-3"
                  @click="handleAddVisitClick"
                >
                  {{ translations.tcAddRestockingVisit }}
                </b-button>
              </div>
            </div>
            <div class="col col-6">
              <NumberCard v-for="(stat, index) in cardVisitsData" :key="index" :obj="stat" :i18n="translations.components" />
            </div>
          </div>
        </section>
        <section class="section-2">
          <h4>{{ translations.tcUpcomingVideoShowings }}</h4>
          <div class="row">
            <div class="col col-6">
              <DateCard v-for="(visit, index) in videoShowingSummary" :key="index" :obj="visit.date" :i18n='translations.common'>
                <BasicDateCard v-if="translations.common" :obj="visit" :i18n="translations.components" />
              </DateCard>
              <div class="d-flex button-bar mb-5">
                <b-button variant="primary" class="mr-2 mb-3" @click="handleVideoListClick">
                  {{ translations.tcViewMore }}
                </b-button>
                <b-button
                  v-if="iCanSee(secured_gideoncard_visit_add_controls.add_gideoncard_video_presentation_button)"
                  variant="primary"
                  class="mr-2 mb-3"
                  @click="handleAddPresentationClick"
                >
                  {{ translations.tcAddVideoPresentation }}
                </b-button>
              </div>
            </div>
            <div class="col col-6">
              <NumberCard v-for="(stat, index) in cardPresentationData" :key="index" :obj="stat" :i18n="translations.components" />
            </div>
          </div>
        </section>
        <section class="section-3">
          <div class="row">
            <InfoCard
              v-for="(info, index) in cardMinistryInfo"
              :key="index"
              :obj="info"
              @set_program="handleSetProgram($event)"
            />
          </div>
        </section>
      </div>
      <!-- / Page Body -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BasicDateCard from '@/components/date-card/Basic.vue'
import CampSelect from '@/components/CampSelect.vue'
import DateCard from '@/components/date-card/DateCard.vue'
import InfoCard from '@/components/info-card/InfoCard.vue'
import NumberCard from '@/components/number-card/NumberCard.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'gideon-card',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      secured_gideoncard_visit_add_controls: {
        add_gideoncard_restocking_visit_button: '7fdd519a-35d1-4eca-b374-e96e8f05efbe',
        add_gideoncard_video_presentation_button: 'ed32320b-3333-444d-9f80-038c2808c769',
      },
      cardVisitsData: [
        {
          num: 0,
          title: 'GideonCard Receipts Fiscal Year',
        },
        {
          num: '?',
          title: 'Facilities Serviced in the past 12 months',
        },
      ],
      cardPresentationData: [
        {
          num: '?',
          title: 'GideonCard facilities Listed in your camp',
        },
        {
          num: '?',
          title: 'Video presentations in the past 36 months',
        },
      ],
      cardMinistryInfo: [
        {
          class: 'col col-6',
          title: 'GideonCard Facility List',
          content: `View a list of all GideonCard facilities in your camp.`,
          link: '/programs/gc/gideon-card-facilities',
          label: 'View More',
          img: {
            src: 'svgs/church-list.svg',
            alt: 'Hand Praying',
            width: 47,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: 'Gideon Card Reports',
          content: 'Run on-demand reports for your GideonCard Facilties',
          link: '/reports/report-center/gc',
          label: 'View More',
          img: {
            src: 'svgs/church-reports.svg',
            alt: 'Church List',
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: 'Program Resources',
          content: 'View manuals, videos, and other resources for GideonCard Program.',
          emitlink: '/resources/media-portal/program',
          itemId: 'BE830EEE-D12B-4DFD-BBF5-B97B979BBD45', // The sitecore ItemId for GideonCard Program
          label: 'View More',
          img: {
            src: 'svgs/program-resources.svg',
            alt: 'Church List',
            width: 64,
            height: 64,
          },
        },
      ],
    }
  },
  components: {
    BasicDateCard: BasicDateCard,
    campSelector: CampSelect,
    DateCard: DateCard,
    InfoCard: InfoCard,
    NumberCard: NumberCard,
    securityBanner: SecurityBanner,
  },
  methods: {
    ...mapActions({
      clearFacilityDetails: 'card/clearFacilityDetails',
      clearRestockingVisitDetails: 'card/clearRestockingVisitDetails',
      clearVideoVisitDetails: 'card/clearVideoVisitDetails',
      getHomeStats: 'card/getHomeStats',
      getRestockingVisits: 'card/getRestockingVisits',
      getVideoShowings: 'card/getVideoShowings',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMRPProgramId: 'mrp/setMRPProgramId',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
    }),
    getFutureVideoDate() {
      let startDate = new Date()
      let startMonth = startDate.getMonth() + 1
      let startDateInString =
        startDate.getFullYear() + '-' + (startMonth < 10 ? '0' + startMonth : startMonth) + '-' + startDate.getDate()

      let endDate = new Date()
      endDate.setMonth(endDate.getMonth() + 36)
      let endMonth = endDate.getMonth() + 1
      let endDateInString =
        endDate.getFullYear() + '-' + (endMonth < 10 ? '0' + endMonth : endMonth) + '-' + endDate.getDate()

      let data = {
        startDate: startDateInString,
        endDate: endDateInString,
      }
      return data
    },
    async page_load() {
      let videoData = this.getFutureVideoDate()
      await Promise.all([
        this.setLoadingStatus(true),
        await this.getHomeStats(),
        await this.getRestockingVisits(),
        await this.getVideoShowings(videoData),
      ]).then(() => {
        this.cardVisitsData[0].num = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
          this.homeStats.donationsYTD
        )
        this.cardVisitsData[1].num = this.homeStats.restockingCount
        this.cardPresentationData[0].num = this.homeStats.facilityCount
        this.cardPresentationData[1].num = this.homeStats.videoCount
        this.setLoadingStatus(false)
      })
    },
    async handleSetProgram(data) {
      this.setMRPProgramId(data.itemId)
      this.$router.push(data.emitlink)
    },
    loadTranslations() {
      this.cardMinistryInfo[0].title = this.translations.tcGideonCardFacilityListTitle
      this.cardMinistryInfo[0].content = this.translations.tcGideonCardFacilityListDescription
      this.cardMinistryInfo[0].label = this.translations.tcViewMore
      this.cardMinistryInfo[1].title = this.translations.tcGideonCardReportsTitle
      this.cardMinistryInfo[1].content = this.translations.tcGideonCardReportsDescription
      this.cardMinistryInfo[1].label = this.translations.tcViewMore
      this.cardMinistryInfo[2].title = this.translations.tcProgramResourcesTitle
      this.cardMinistryInfo[2].content = this.translations.tcProgramResourcesDescription
      this.cardMinistryInfo[2].label = this.translations.tcViewMore
    },
    async handleVisitListClick() {
      this.$router.push({ name: 'restocking-visits' })
    },
    async handleAddVisitClick() {
      this.setSelectedFacilityKey(null)
      this.clearFacilityDetails()
      this.setSelectedVisitKey(null)
      this.clearRestockingVisitDetails()
      this.$router.push('/programs/gc/events/card_restocking_visit_add')
    },
    async handleAddPresentationClick() {
      this.setSelectedFacilityKey(null)
      this.clearFacilityDetails()
      this.setSelectedVisitKey(null)
      this.clearVideoVisitDetails()
      this.$router.push('/programs/gc/events/card_video_presentation_add')
    },
    async handleVideoListClick() {
      this.$router.push({ name: 'video-showings' })
    },
  },
  async created() {
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('basic-date-card', 'camp-select', 'date-card', 'number-card', 'security-banner'),
      this.getComponentTranslations('common.days', 'common.months-abbreviations'),
    ]).then(results => {
      this.stripReadableText(results[2])
      const translatedText = {
        ...results[1],
        common: { ...this.translations.common }
      }
      this.$set(this.translations, 'components', translatedText)

      this.loadTranslations()
    })
  },
  computed: {
    ...mapGetters({
      homeStats: 'card/getHomeStats',
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
      restockingVisitSummary: 'card/restockingVisitSummary',
      userOrgKey: 'user/userOrgKey',
      videoShowingSummary: 'card/videoShowingSummary',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
  },
}
</script>

<style lang="scss">
/* eslint-disable */
@import '@/styles/settings.scss';
.church-ministry {
  .page-body {
    margin-top: 68px;
    @include breakpoint(sm) {
      margin-top: 3rem;
    }
    h4 {
      margin-bottom: 36px;
    }
  }
  .section-0 {
    margin-bottom: 60px;
    .quote-verse p {
      font-family: $lora;
      font-size: 20px;
      line-height: 34px;
      letter-spacing: 1px;
    }
  }
  .section-1,
  .section-2 {
    margin-bottom: 80px;
    @include breakpoint(sm) {
      margin-bottom: 3rem;
    }
    > .row {
      margin-bottom: 28px;
    }
    .col {
      &:first-of-type {
        padding-right: 23px;
      }
      &:last-of-type {
        padding-left: 23px;
      }
      @include breakpoint(sm) {
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
    .number-card .content .w-subtitle p:not(.subtitle) {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0;
    }
  }
  .section-1 {
    .d-flex.button-bar {
      align-items: center;
      @include breakpoint(sm) {
        flex-wrap: wrap;
      }
    }
    .number-card .wo-subtitle p {
      font-size: 26px;
      line-height: 32px;
    }
    .sub-text {
      font-family: $DIN;
      text-transform: uppercase;
      font-size: 20px;
      @include breakpoint(sm) {
        display: block;
        margin-left: 0 !important;
        margin-top: 30px;
      }
    }
  }
  .section-2 {
    .sub-support {
      .num {
        display: flex;
        align-items: center;
      }
    }
  }
  .section-3 {
    margin-bottom: 60px;
    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }
      &:nth-child(2n) {
        padding-left: 23px;
      }
    }
    .number-card {
      align-items: center;
    }
    .row {
      justify-content: center;
    }
    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
</style>
